<template>
  <b-sidebar
      id="add-new-contact-sidebar"
      :visible="isAddNewContactSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-contact-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Contact
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
          >
            <b-form-group
                label="Name"
                label-for="name"
            >
              <b-form-input
                  id="name"
                  v-model="contactData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Surname -->
          <validation-provider
              #default="validationContext"
              name="Surname"
              rules=""
          >
            <b-form-group
                label="Surname"
                label-for="surname"
            >
              <b-form-input
                  id="surname"
                  v-model="contactData.surname"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Phone Number -->
          <validation-provider
              #default="validationContext"
              name="PhoneNumber"
              rules="required"
          >
            <b-form-group
                label="Phone Number"
                label-for="phoneNumber"
            >
              <b-form-input
                  id="phoneNumber"
                  v-model="contactData.phoneNumber"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Department -->
          <validation-provider
              #default="validationContext"
              name="Department"
              rules=""
          >
            <b-form-group
                label="Department"
                label-for="department"
            >
              <b-form-input
                  id="department"
                  v-model="contactData.department"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Title -->
          <validation-provider
              #default="validationContext"
              name="Title"
              rules=""
          >
            <b-form-group
                label="Title"
                label-for="title"
            >
              <b-form-input
                  id="title"
                  v-model="contactData.title"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              Add
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'


import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {
    VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar , BForm, BFormInvalidFeedback
} from 'bootstrap-vue'
export default {
  name: 'ContactListAddNew',
  components: {
    vSelect,
    flatPickr,
    VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar,BForm, BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewContactSidebarActive',
    event: 'update:is-add-new-contact-sidebar-active',
  },
  props: {
    isAddNewContactSidebarActive: {
      type: Boolean,
      required: true,
    }

  },
  data() {
    return {
      required,
      alphaNum
    }
  },
  setup(props, { emit }) {

    const toast = useToast()
    const blankContactData = {
      name: '',
      description: '',
      file: null,
      fileName: '',
      filePath: '',
      order: 1

    }

    const contactData = ref(JSON.parse(JSON.stringify(blankContactData)))
    const resetcontactData = () => {
      contactData.value = JSON.parse(JSON.stringify(blankContactData))
    }



    const onSubmit = () => {

      store.dispatch('createContact', contactData.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-contact-sidebar-active', false)
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error adding contacts list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })

    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcontactData)

    return {
      contactData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-contact-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
